import { useRootLayout } from "@/hooks/useRootLayout";

import { Box, Stack, Typography } from "@mui/material";
import { FC, Suspense, lazy, useEffect } from "react";
import { Route, Routes } from "react-router";
import { withSentryReactRouterV6Routing } from "@sentry/react";
import getLeasingRoutes from "@/router/LeasingRoutes";

import Stencils from "@/pages/Stencils";
import getConciergeSaleRoutes from "./ConciergeSaleRoutes";
import getInspectionRoutes from "./InspectionRoutes";
import CircularLoading from "@/pages/inspection-job-report-dynamic/views/categories/CircularLoading";

const RootLayout = lazy(() => import("@/components/layouts/RootLayout"));
const Home = lazy(() => import("@/pages/Home/index"));
const Login = lazy(() => import("@/pages/Login/index"));
const Profile = lazy(() => import("@/pages/Profile/index"));
const ProfileDetail = lazy(() => import("@/pages/ProfileDetail/index"));
const Notification = lazy(() => import("@/pages/Notification/index"));
const FinanceReport = lazy(() => import("@/pages/FinanceReport/index"));
const PerformanceReport = lazy(() => import("@/pages/PerformanceReport/index"));
const AssistantAvailability = lazy(
  () => import("@/pages/assistant-availability/index")
);

const SentryRoutes = withSentryReactRouterV6Routing(Routes);

const RootRouter: FC = () => {
  const { setHeaderTitle } = useRootLayout();

  useEffect(() => {
    if (typeof setHeaderTitle === "function") {
      setHeaderTitle("شیفت‌های کاری");
    }
  }, [setHeaderTitle]);

  return (
    <SentryRoutes>
      <Route
        path={import.meta.env.BASE_URL}
        element={
          <Suspense fallback={<CircularLoading />}>
            <RootLayout />
          </Suspense>
        }
      >
        <Route
          index
          element={
            <Suspense fallback={<CircularLoading />}>
              <Home />
            </Suspense>
          }
        />

        <Route
          path="/login"
          element={
            <Suspense fallback={<CircularLoading />}>
              <Login />
            </Suspense>
          }
        />
        <Route path="/profile">
          <Route
            index
            element={
              <Suspense fallback={<CircularLoading />}>
                <Profile />
              </Suspense>
            }
          />
          <Route
            path="/profile/detail"
            element={
              <Suspense fallback={<CircularLoading />}>
                <ProfileDetail />
              </Suspense>
            }
          />
          <Route
            path="/profile/availability-management"
            element={
              <Suspense fallback={<CircularLoading />}>
                <AssistantAvailability />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="/notification"
          element={
            <Suspense fallback={<CircularLoading />}>
              <Notification />
            </Suspense>
          }
        />
        <Route
          path="/finance-report"
          element={
            <Suspense fallback={<CircularLoading />}>
              <FinanceReport />
            </Suspense>
          }
        />
        <Route
          path="/performance-report"
          element={
            <Suspense fallback={<CircularLoading />}>
              <PerformanceReport />
            </Suspense>
          }
        />
        {getLeasingRoutes()}

        {getConciergeSaleRoutes()}

        {getInspectionRoutes()}

        <Route path="stencils" element={<Stencils />} />

        <Route
          path="*"
          element={
            <Box
              sx={{
                position: "fixed",
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              صفحه مورد نظر یافت نشد!
            </Box>
          }
        />
      </Route>
    </SentryRoutes>
  );
};

export default RootRouter;
