import React, { FC, useMemo, useState } from "react";
import styles from "./styles";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  IconButton,
  Stack,
  TextField,
  Typography
} from "@mui/material";

import { useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import SearchRounded from "@mui/icons-material/SearchRounded";
import { FilterList } from "@mui/icons-material";
import CloseRounded from "@mui/icons-material/CloseRounded";
import { useGetOrderDynamicStates } from "@/services/api/leasing/requests";
import { useNavigate } from "react-router";
import { useGetProviderList } from "@/services/api/leasing-order";
import { useGetCities } from "@/services/api/leasing/city";
import { convertDateToTile } from "@/pages/leasing/requests/components/Filter/date-filter/utils";
import DateFilter from "@/pages/leasing/requests/components/Filter/date-filter";
import { citiesList } from "@/pages/concierge-sale-submit-order/configs";

const Filter: FC = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const querySearch = params.get("search") || "";
  const querycategory = params.get("category") || "";
  const queryStatus = params.getAll("status");
  const queryProvider = params.getAll("provider");
  const queryCities = params.getAll("city");
  const queryStartOrder = params.get("orderStart") || undefined;
  const queryEndOrder = params.get("orderEnd") || undefined;
  const queryStartNote = params.get("noteStart") || undefined;
  const queryEndNote = params.get("noteEnd") || undefined;
  const queryChannel = params.get("channel") || undefined;
  const queyIsDocumentsRejected =
    params.get("isDocumentsRejected") || undefined;

  const [dateBS, setDateBS] = useState<"order" | "note" | undefined>(undefined);

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const { data: packages, isLoading } = useGetProviderList();

  const providerPackages = useMemo(() => {
    if (!packages) return [];
    return packages;
  }, [packages]);

  const { data: leasingStatusList, isLoading: leasingStatusLoading } =
    useGetOrderDynamicStates();

  const formik = useFormik({
    initialValues: {
      search: querySearch,
      status: queryStatus,
      category: querycategory,
      provider: queryProvider,
      cities: queryCities,
      noteStart: queryStartNote,
      noteEnd: queryEndNote,
      orderStart: queryStartOrder,
      orderEnd: queryEndOrder,
      channel: queryChannel,
      isDocumentsRejected: queyIsDocumentsRejected
    },
    onSubmit: (values) => {
      let newQueryStr = "";
      if (values.search) {
        newQueryStr += `search=${values.search}&`;
      }
      if (values.category) {
        newQueryStr += `category=${values.category}&`;
      }
      if (values.status.length) {
        values.status.forEach((statusItem) => {
          newQueryStr += `status=${statusItem}&`;
        });
      }
      if (values.provider.length) {
        values.provider.forEach((statusItem) => {
          newQueryStr += `provider=${statusItem}&`;
        });
      }
      if (values.cities.length) {
        values.cities.forEach((city) => {
          newQueryStr += `city=${city}&`;
        });
      }
      if (values.noteStart) {
        newQueryStr += `noteStart=${values.noteStart}&`;
      }
      if (values.noteEnd) {
        newQueryStr += `noteEnd=${values.noteEnd}&`;
      }
      if (values.orderStart) {
        newQueryStr += `orderStart=${values.orderStart}&`;
      }
      if (values.orderEnd) {
        newQueryStr += `orderEnd=${values.orderEnd}&`;
      }
      if (values.channel) {
        newQueryStr += `channel=${values.channel}&`;
      }
      if (values.isDocumentsRejected) {
        newQueryStr += `isDocumentsRejected=${values.isDocumentsRejected}&`;
      }
      navigate(`/leasing/requests${newQueryStr ? `?${newQueryStr}` : ""}`);
    }
  });

  const leasingStatusListItems: { key: string; value: string }[] =
    useMemo(() => {
      if (!leasingStatusList) return [];
      const apiValues: { key: string; value: string }[] = leasingStatusList.map(
        (item) => {
          return {
            key: item?.slug,
            value: item?.name_fa
          };
        }
      );
      return [...apiValues, { value: "لغو شده", key: "is_rejected" }];
    }, [leasingStatusList]);

  const { data: cities, isLoading: citiesLoading } = useGetCities();

  const selectedStatusFilters = useMemo(() => {
    if (!leasingStatusList) return [];
    return leasingStatusList.filter((item) => queryStatus.includes(item.slug));
  }, [leasingStatusList, queryStatus]);

  const selectedProviderFilters = useMemo(() => {
    if (!packages) return [];
    return packages.filter((item) =>
      queryProvider.includes(item.id.toString())
    );
  }, [packages, queryProvider]);

  const selectedCitiesFilters = useMemo(() => {
    if (!cities) return [];
    return cities.data.filter((item) =>
      queryCities.includes(item.id.toString())
    );
  }, [cities, queryCities]);

  return (
    <>
      <Stack
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
        sx={styles.searchContainer}
      >
        <TextField
          size="small"
          sx={styles.search}
          label="جستجو شماره تلفن یا سفارش"
          value={formik.values.search}
          name="search"
          onChange={formik.handleChange}
          InputProps={{
            endAdornment: (
              <IconButton type="submit">
                <SearchRounded />
              </IconButton>
            )
          }}
        />
      </Stack>
      <Stack sx={styles.lablesContainer}>
        <Button
          startIcon={<FilterList />}
          sx={styles.filterButton}
          color="secondary"
          variant="outlined"
          onClick={() => setIsFiltersOpen(!isFiltersOpen)}
        >
          فیلترها
        </Button>
      </Stack>
      {(queryStartOrder || queryEndOrder) && (
        <Stack
          sx={{
            overflowX: "auto",
            overflowY: "hidden",
            gap: 1,
            p: 2
          }}
          width="100%"
          flexDirection="row"
        >
          <Chip
            variant="filled"
            color="secondary"
            label={`تاریخ سفارش - ${convertDateToTile(
              queryStartOrder,
              queryEndOrder
            )}`}
            onDelete={() => {
              formik.setFieldValue("orderStart", undefined);
              formik.setFieldValue("orderEnd", undefined);
              formik.handleSubmit();
            }}
          />
        </Stack>
      )}
      {(queryEndNote || queryStartNote) && (
        <Stack
          sx={{
            overflowX: "auto",
            overflowY: "hidden",
            gap: 1,
            p: 2
          }}
          width="100%"
          flexDirection="row"
        >
          <Chip
            variant="filled"
            color="secondary"
            label={`تاریخ یاددشت - ${convertDateToTile(
              queryStartNote,
              queryEndNote
            )}`}
            onDelete={() => {
              formik.setFieldValue("noteStart", undefined);
              formik.setFieldValue("noteEnd", undefined);
              formik.handleSubmit();
            }}
          />
        </Stack>
      )}
      {selectedStatusFilters.length > 0 && (
        <Stack
          sx={{
            overflowX: "auto",
            overflowY: "hidden",
            gap: 1,
            p: 2
          }}
          width="100%"
          flexDirection="row"
        >
          {selectedStatusFilters.map((item) => {
            return (
              <Chip
                variant="filled"
                color="secondary"
                key={item.slug}
                label={item.name_fa}
                onDelete={() => {
                  const newFormik = [...formik.values.status];
                  console.log(newFormik, item.slug);
                  newFormik.splice(formik.values.status.indexOf(item.slug), 1);
                  formik.setFieldValue("status", newFormik);
                  formik.handleSubmit();
                }}
              />
            );
          })}
        </Stack>
      )}
      {selectedProviderFilters.length > 0 && (
        <Stack
          sx={{
            overflowX: "auto",
            overflowY: "hidden",
            gap: 1,
            p: 2
          }}
          width="100%"
          flexDirection="row"
        >
          {selectedProviderFilters.map((item) => {
            return (
              <Chip
                variant="filled"
                color="secondary"
                key={item.id}
                label={item.name_fa}
                onDelete={() => {
                  const newFormik = [...formik.values.provider];
                  newFormik.splice(
                    formik.values.provider.indexOf(item.id.toString()),
                    1
                  );
                  formik.setFieldValue("provider", newFormik);
                  formik.handleSubmit();
                }}
              />
            );
          })}
        </Stack>
      )}

      {selectedCitiesFilters.length > 0 && (
        <Stack
          sx={{
            overflowX: "auto",
            overflowY: "hidden",
            gap: 1,
            p: 2
          }}
          width="100%"
          flexDirection="row"
        >
          {selectedCitiesFilters.map((item) => {
            return (
              <Chip
                variant="filled"
                color="secondary"
                key={`city-filter-${item.id}`}
                label={item.name}
                onDelete={() => {
                  const newFormik = [...formik.values.cities];
                  newFormik.splice(
                    formik.values.cities.indexOf(item.id.toString()),
                    1
                  );
                  formik.setFieldValue("cities", newFormik);
                  formik.handleSubmit();
                }}
              />
            );
          })}
        </Stack>
      )}

      <Box
        sx={{
          ...styles.backdrop,
          opacity: isFiltersOpen ? 1 : 0,
          pointerEvents: isFiltersOpen ? "all" : "none"
        }}
        component="div"
        onClick={() => setIsFiltersOpen(false)}
      />
      <Stack
        sx={{
          ...styles.modalContainer,
          transform: isFiltersOpen ? "translateY(0px)" : "translateY(50%)",
          opacity: isFiltersOpen ? 1 : 0,
          pointerEvents: isFiltersOpen ? "all" : "none",
          height: "100%"
        }}
      >
        <Stack sx={styles.filterHeaderContainer}>
          <Typography fontSize="1.125rem" fontWeight="500">
            انتخاب فیلتر
          </Typography>
          <IconButton onClick={() => setIsFiltersOpen(false)}>
            <CloseRounded />
          </IconButton>
        </Stack>
        <Stack
          sx={{
            ...styles.contentsContainer,
            height: "100%",
            justifyContent: "space-between"
          }}
        >
          <Stack spacing={3}>
            <Autocomplete
              loading={leasingStatusLoading}
              size="small"
              disablePortal
              disabled={!leasingStatusListItems}
              multiple
              value={leasingStatusListItems.filter((item) =>
                formik.values.status.includes(item.key)
              )}
              options={leasingStatusListItems}
              onChange={(_, value) => {
                formik.setFieldValue(
                  "status",
                  value.map((item) => item.key)
                );
              }}
              getOptionLabel={(item) => item.value}
              sx={{ width: "100%" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{
                    shrink: true
                  }}
                  label="وضعیت"
                />
              )}
            />
            <Autocomplete
              loading={isLoading}
              size="small"
              disablePortal
              multiple
              value={providerPackages?.filter((item) =>
                formik.values.provider.includes(item.id.toString())
              )}
              disabled={!packages}
              options={providerPackages}
              onChange={(_, value) => {
                formik.setFieldValue(
                  "provider",
                  value.map((item) => item.id.toString())
                );
              }}
              getOptionLabel={(item) => item.name_fa}
              sx={{ width: "100%" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{
                    shrink: true
                  }}
                  placeholder="جستجو پگیج"
                  label=" نوع تامین کننده"
                />
              )}
            />

            <Autocomplete
              loading={isLoading}
              size="small"
              disablePortal
              options={CATEGOTY_TYPES}
              onChange={(_, value) => {
                formik.setFieldValue(
                  "category",
                  value ? value.value : undefined
                );
              }}
              value={CATEGOTY_TYPES.find(
                (item) => item.value === formik.values.category
              )}
              getOptionLabel={(item) => item.title}
              sx={{ width: "100%" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{
                    shrink: true
                  }}
                  placeholder="جستجو تگ یادداشت"
                  label="تگ یادداشت"
                />
              )}
            />

            <Autocomplete
              loading={citiesLoading}
              size="small"
              disablePortal
              multiple
              options={citiesList}
              disabled={!cities}
              onChange={(_, value) => {
                formik.setFieldValue(
                  "cities",
                  value.map((item) => item.id.toString())
                );
              }}
              value={citiesList.filter((item) =>
                formik.values.cities.includes(item.id.toString())
              )}
              getOptionLabel={(item) => item.label}
              sx={{ width: "100%" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{
                    shrink: true
                  }}
                  placeholder="جستجو شهر"
                  label="شهر"
                />
              )}
            />

            <Autocomplete
              size="small"
              disablePortal
              options={CHANNEL_LIST}
              getOptionLabel={(item) => item.title}
              sx={{ width: "100%" }}
              value={CHANNEL_LIST.find((item) =>
                formik.values.channel?.includes(item.value)
              )}
              onChange={(_, value) => {
                formik.setFieldValue(
                  "channel",
                  value ? value.value : undefined
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{
                    shrink: true
                  }}
                  label="کانال ثبت"
                />
              )}
            />
            <TextField
              placeholder="تاریخ ساخت یادداشت"
              fullWidth
              onClick={() => setDateBS("note")}
              sx={{
                color: "text.primary",
                fontWeight: "500",
                fontSize: "1rem"
              }}
              InputProps={{
                readOnly: true
              }}
              label="تاریخ ساخت یادداشت"
              variant="outlined"
              value={`${convertDateToTile(
                formik.values.noteStart,
                formik.values.noteEnd
              )}`}
            />

            <TextField
              placeholder="تاریخ ساخت سفارش"
              fullWidth
              onClick={() => setDateBS("order")}
              sx={{
                color: "text.primary",
                fontWeight: "500",
                fontSize: "1rem"
              }}
              InputProps={{
                readOnly: true
              }}
              label="تاریخ ساخت سفارش"
              variant="outlined"
              value={convertDateToTile(
                formik.values.orderStart,
                formik.values.orderEnd
              )}
            />
            <Divider variant="fullWidth" />

            <Stack
              px={1}
              width="100%"
              flexDirection="row"
              justifyContent="start"
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.isDocumentsRejected === "true"}
                    onChange={() =>
                      formik.setFieldValue(
                        "isDocumentsRejected",
                        formik.values.isDocumentsRejected === "true"
                          ? undefined
                          : "true"
                      )
                    }
                    color="secondary"
                  />
                }
                label={
                  <Typography
                    sx={{
                      color: "text.primary",
                      mt: 1,
                      fontWeight: "500",
                      fontSize: "1rem"
                    }}
                  >
                    اسناد رد شده
                  </Typography>
                }
              />
            </Stack>
          </Stack>
          <Button
            onClick={() => {
              setIsFiltersOpen(false);
              formik.handleSubmit();
            }}
            sx={styles.filterSubmitButton}
          >
            اعمال فیلتر
          </Button>
        </Stack>
      </Stack>
      <DateFilter
        onSubmit={(start, end) => {
          if (dateBS === "note") {
            formik.setFieldValue("noteStart", start);
            formik.setFieldValue("noteEnd", end);
          }
          if (dateBS === "order") {
            formik.setFieldValue("orderStart", start);
            formik.setFieldValue("orderEnd", end);
          }
          setDateBS(undefined);
        }}
        startDate={dateBS === "note" ? queryStartNote : queryStartOrder}
        endDate={dateBS === "order" ? queryEndOrder : queryEndNote}
        title={dateBS === "note" ? "تاریخ ساخت یادداشت" : "تاریخ ساخت سفارش"}
        isOpen={!!dateBS}
        onClose={() => setDateBS(undefined)}
      />
    </>
  );
};

export default Filter;

export const CATEGOTY_TYPES = [
  { title: "همه", value: "" },
  { title: "بدون یادداشت", value: "no-note" },
  {
    title: "تماس بی پاسخ",
    value: "missed-call"
  },
  {
    title: "تماس با مشتری",
    value: "note-call"
  },
  {
    title: "نیاز به پیگیری",
    value: "need-to-follow-up"
  }
];

export const CHANNEL_LIST = [
  { title: "آگهی دیوار غیرکارنامه ای", value: "DNK" },
  { title: "آگهی دیوار کارنامه ای", value: "DNK" },
  { title: "آگهی های خودرو", value: "CP" },
  { title: "آگهی لیستینگ غیر کارنامه ای", value: "CPNK" },
  { title: "لندینگ وبسایت", value: "WL" },
  { title: "بک آفیس", value: "B" },
  { title: "اپلیکیشن دستیار فروش", value: "SA" },
  { title: "دفاتر فروش - اپ دستیار", value: "SOAA" },
  { title: "دفاتر فروش - بک آفیس", value: "SOB" }
];
