import { FC, useState } from "react";
import { Button, Chip, Snackbar, Stack, Typography } from "@mui/material";
import PaymentsIcon from "@mui/icons-material/Payments";
import BarCode from "@/assets/icons/barcode.svg";
import { styles } from "@/pages/ConciergeSale/requests/tabs/details/info/submitDeals/style";
import Person from "@mui/icons-material/Person";
import PhoneEnabled from "@mui/icons-material/PhoneEnabled";

interface Props {
  amount: number;
  id: number;
  invoice_token: string;
  payment_intent: string;
  payment_link: string;
  created_at: string;
  modified_at: string;
  name: string;
  phone_number: string;
  state:
    | "in-progress"
    | "failed"
    | "success"
    | "cancelled-by-user"
    | "reversed";
}

const PaymentCard: FC<Props> = ({
  created_at,
  state,
  amount,
  invoice_token,
  name,
  phone_number,
  payment_intent
}) => {
  const [toast, setToast] = useState(false);

  const statusBadge = (state: string) => {
    switch (state) {
      case "success":
        return (
          <Chip
            sx={{
              ...styles.badge,
              bgcolor: "#EBF8F1",
              color: "#00A754"
            }}
            label="پرداخت موفق"
          />
        );
      case "in-progress":
        return (
          <Chip
            sx={{
              ...styles.badge,
              bgcolor: "#EDEBF4",
              color: "#694FB4"
            }}
            label="در انتظار پرداخت"
          />
        );
      case "failed":
        return (
          <Chip
            sx={{
              ...styles.badge,
              bgcolor: "#FFF4F4",
              color: "#CD0000"
            }}
            label="پرداخت ناموفق"
          />
        );
      case "cancelled-by-user":
        return (
          <Chip
            sx={{
              ...styles.badge,
              bgcolor: "#FFF4F4",
              color: "#CD0000"
            }}
            label="لغو شده توسط کاربر"
          />
        );
      // case "R":
      //   return (
      //     <Chip
      //       sx={{
      //         ...styles.badge,
      //         bgcolor: "#FFF1E5",
      //         color: "#FA6B00"
      //       }}
      //       label="بازگشت وجه"
      //     />
      //   );
      default:
        return (
          <Chip
            sx={{
              ...styles.badge,
              bgcolor: "#eee",
              color: "#000"
            }}
            label="نامشخص"
          />
        );
    }
  };

  const copyToClipBoard = async (data: string) => {
    await navigator.clipboard.writeText(data).then(() => setToast(true));
  };

  return (
    <>
      <Stack
        sx={{
          background: "#fff",
          borderRadius: "4px",
          border: "1px solid #ebebeb",
          width: "100%",
          p: 2,
          mb: 2
        }}
      >
        <Stack
          sx={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid #EBEBEB",
            mb: 2,
            pb: 1.5
          }}
        >
          <Typography sx={{ fontWeight: 500 }}>
            {new Date(created_at).toLocaleTimeString("fa", {
              hour: "numeric",
              minute: "2-digit"
            })}{" "}
            - {new Date(created_at).toLocaleDateString("fa")}
          </Typography>
          {statusBadge(state as string)}
        </Stack>
        <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
          <Person sx={{ color: "#6E6E6E", mr: 2, fontSize: "26px" }} />
          <Typography fontSize="18px">{name || "-"}</Typography>
        </Stack>
        <Stack sx={{ flexDirection: "row", alignItems: "center", mt: 1.5 }}>
          <PhoneEnabled sx={{ color: "#6E6E6E", mr: 2, fontSize: "26px" }} />
          <Typography fontSize="18px">{phone_number || "-"}</Typography>
        </Stack>
        <Stack sx={{ flexDirection: "row", alignItems: "center", mt: 1.5 }}>
          <img style={{ width: "26px" }} src={BarCode} alt="barcode icon" />
          <Typography fontSize="18px" ml={2}>
            {invoice_token || "-"}
          </Typography>
        </Stack>
        <Stack sx={{ flexDirection: "row", alignItems: "center", mt: 1.5 }}>
          <PaymentsIcon sx={{ color: "#6E6E6E", mr: 2, fontSize: "26px" }} />
          <Typography fontSize="18px">
            {Number(amount).toLocaleString()} تومان
          </Typography>
        </Stack>
        <Button
          onClick={() => copyToClipBoard(String(payment_intent))}
          variant="outlined"
          size="medium"
          sx={{ mt: 2 }}
        >
          کپی لینک پرداخت
        </Button>
      </Stack>

      <Snackbar
        autoHideDuration={1000}
        ContentProps={{
          sx: {
            background: "#00a754"
          }
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={toast}
        onClose={() => setToast(false)}
        message="لینک پرداخت کپی شد"
      />
    </>
  );
};

export default PaymentCard;
