import axios, { AxiosResponse } from "axios";
import {
  QueryKey,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult
} from "react-query";
// import { createStandaloneToast } from '@chakra-ui/toast'

// const { toast } = createStandaloneToast()

export interface ApiErrorResponse {
  [name: string]: string[] | undefined;

  non_field_errors?: string[];
  message?: string[];
}

export interface FieldErrors {
  [name: string]: string[] | undefined;
}

export type ApiErrorCodes =
  | number
  | "NOT_IMPLEMENTED_ERROR"
  | "UNREACHABLE_ERROR"
  | "UNKNOWN_ERROR";

export class ApiError extends Error {
  constructor(
    public message: string,
    public code: ApiErrorCodes,
    public fieldErrors: FieldErrors,
    public originalError?: unknown
  ) {
    super(message);
  }
}

function convertApiError(err: unknown): ApiError {
  if (err instanceof ApiError) return err;
  if (axios.isAxiosError(err)) {
    const response: ApiErrorResponse = err.response?.data;
    const errorMessage = response?.message?.join(" ,");
    return new ApiError(
      errorMessage ?? "لطفا مجدد تلاش کنید.",
      err.request.status,
      {
        ...response
      },
      err
    );
  } else {
    return new ApiError("لطفا مجدد تلاش کنید.", "UNKNOWN_ERROR", {}, err);
  }
}

/**
 * Convert unknown error to strongly typed error and
 * log bad errors (which are likely to be bugs) so they can
 * be picked up by sentry.io.
 */
export function handleApiError(err: unknown): ApiError {
  // no need to double logging, so if it already was converted, we just return it
  if (err instanceof ApiError) return err;
  const apiError = convertApiError(err);
  if (apiError.code === "UNKNOWN_ERROR") {
    console.error(
      "Unknown api error:",
      apiError?.message,
      apiError.code,
      apiError.originalError
    );
  }
  return apiError;
}

/**
 * @throws {ApiError}
 */
export async function handleApiResult<T = unknown>(
  promise: Promise<AxiosResponse<T>>
): Promise<T> {
  try {
    const response = await promise;
    if ((response.status === 200 || response.status === 201) && response.data) {
      return response.data as T;
    } else {
      throw response;
    }
  } catch (err: unknown) {
    throw handleApiError(err);
  }
}

export type ApiQueryOptions<Result, SelectResult = Result> = Omit<
  UseQueryOptions<Result, ApiError, SelectResult>,
  "queryFn" // queryFn is omitted because it is set at query hook creation
>;

type ApiQueryHook<Params, Result> = <SelectResult = Result>(
  params: Params,
  options?: ApiQueryOptions<Result, SelectResult>
) => UseQueryResult<SelectResult, ApiError>;

export const buildApiQueryHook = <Params, Result>(
  cacheKey: string | ((params: Params) => QueryKey),
  fetch: (params: Params) => Promise<AxiosResponse>
): ApiQueryHook<Params, Result> => {
  return <SelectResult = Result>(
    params: Params,
    options?: ApiQueryOptions<Result, SelectResult>
  ) => {
    return useQuery<Result, ApiError, SelectResult>(
      options?.queryKey ||
        (typeof cacheKey === "string" ? [cacheKey, params] : cacheKey(params)),
      () => handleApiResult<Result>(fetch(params)),
      {
        ...options,
        onError(error: ApiError) {
          if (options?.onError) options.onError(error);
          else {
            // toast({
            //   title: "خطا",
            //   description: error?.message,
            //   variant: "apiError",
            //   duration: 2000
            // });
          }
        }
      }
    );
  };
};

type ApiQueryNoParamsHook<Result> = <SelectResult = Result>(
  options?: ApiQueryOptions<Result, SelectResult>
) => UseQueryResult<SelectResult, ApiError>;

export const buildApiQueryNoParamsHook = <Result>(
  cacheKey: string,
  fetch: () => Promise<AxiosResponse>
): ApiQueryNoParamsHook<Result> => {
  return <SelectResult = Result>(
    options?: ApiQueryOptions<Result, SelectResult>
  ) => {
    return useQuery<Result, ApiError, SelectResult>(
      options?.queryKey || cacheKey,
      () => handleApiResult<Result>(fetch()),
      {
        ...options,
        onError(error: ApiError) {
          if (options?.onError) options.onError(error);
          else {
            // toast({
            //   title: 'خطا',
            //   description: error?.message,
            //   variant: 'apiError',
            //   duration: 2000,
            // })
          }
        }
      }
    );
  };
};

export type ApiMutationOptions<Result, Params> = Omit<
  UseMutationOptions<Result, ApiError, Params>,
  "mutationFn"
>;

export const buildApiMutationHook = <Params = void, Result = void>(
  fetch: (params: Params) => Promise<AxiosResponse>,
  mapOptions: (
    options?: ApiMutationOptions<Result, Params>
  ) => undefined | ApiMutationOptions<Result, Params> = (x) => x
): ((
  options?: ApiMutationOptions<Result, Params>
) => UseMutationResult<Result, ApiError, Params>) => {
  return (options?: ApiMutationOptions<Result, Params>) => {
    options = mapOptions(options);
    return useMutation<Result, ApiError, Params>(
      (params: Params) => handleApiResult<Result>(fetch(params)),
      {
        ...options,
        onError(error, ...rest) {
          if (options?.onError) options.onError(error, ...rest);
          else {
            if (!error.fieldErrors || error.code !== 401) {
              // toast({
              //   title: "خطا",
              //   description: error?.message,
              //   variant: "apiError",
              //   duration: 2000
              // });
            }
          }
        }
      }
    );
  };
};
