import { http2 } from "@/services/http-clients";
import { UseQueryOptions, useMutation, useQuery } from "react-query";

//#region Get Leasing Payment
export interface GetLeasingPaymentResponse {
  id: number;
  amount: number;
  state: string;
  created_at: string;
  name: string;
  phone_number: string;
  invoice_token: string;
  link: string;
}

export interface LeasingPaymentParams {
  service_name: string;
  leasing_id: string;
}

const getLeasingPayment = async (params: LeasingPaymentParams) => {
  return http2<GetLeasingPaymentResponse[]>({
    method: "GET",
    url: ``,
    params: {
      service_name: params.service_name,
      leasing_id: params.leasing_id
    }
  }).then((res) => res.data);
};

const getLeasingPaymentKey = "getLeasingPayment";

export const useGetLeasingStatus = (
  params: LeasingPaymentParams,
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof getLeasingPayment>>,
    // unknown,
    unknown,
    Awaited<ReturnType<typeof getLeasingPayment>>
  >
) => {
  return useQuery({
    queryKey: [getLeasingPaymentKey],
    staleTime: 0,
    queryFn: () => {
      return getLeasingPayment(params);
    },
    ...options
  });
};

//#endregion

//#region create invoice

export interface CreateInvoice {
  phone_number?: string | null;
  name?: string | null;
  amount?: number | null;
  service_name?: string;
  business_type?: string;
  redirect_url?: string;
  send_sms?: boolean;
  concierge_sale_id?: number | null;
  leasing_id?: number;
}

const postCreateInvoice = async (id: number, data: CreateInvoice) => {
  return http2<CreateInvoice>({
    method: "POST",
    url: `/payment/v1/create_invoice`,
    data
  });
};

const postCreateInvoiceKey = "postCreateInvoice";

export const usePostCreateInvoice = (id: number) => {
  return useMutation({
    mutationKey: [postCreateInvoiceKey],
    mutationFn: (data: CreateInvoice) => {
      return postCreateInvoice(id, data);
    }
  });
};

//#endregion

//#region Get Payments List

export interface GetPaymentListsParams {
  service_name: string[] | string;
  concierge_sale_id?: number;
  leasing_id?: string;
}

export interface GetPaymentListsResponse {
  id: number;
  amount: number;
  state: string;
  paid_at?: string;
  created_at: string;
  closed_at?: string;
  name: string;
  phone_number: string;
  invoice_token: string;
  link: string;
  service_name: string;
}
[];

const getPaymentLists = async (params: GetPaymentListsParams) => {
  return http2<GetPaymentListsResponse>({
    method: "GET",
    url: `/payment/v1/payments`,
    paramsSerializer: {
      indexes: null
    },
    params: {
      service_name: params.service_name,
      concierge_sale_id: params.concierge_sale_id,
      leasing_id: params.leasing_id
    }
  });
};

const getPaymentListsKey = "getPaymentLists";

export const useGetPaymentLists = (
  params: GetPaymentListsParams,
  options?: UseQueryOptions
) => {
  return useQuery({
    queryKey: [getPaymentListsKey, params.leasing_id, params.concierge_sale_id],
    staleTime: 0,
    queryFn: () => {
      return getPaymentLists(params)
        .then((res) => {
          return res.data as GetPaymentListsResponse;
        })
        .catch(() => {
          return [] as GetPaymentListsResponse[];
        });
    },
    ...options
  });
};

//#endregion
