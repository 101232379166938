import {
  MutationFunction,
  MutationKey,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery
} from "react-query";
import { http2, httpPostStorage, httpRest } from "../http-clients";
import { GlobalErrorModel } from "@/utils/error-handling";
import { AxiosError } from "axios";

export interface SalesExpert {
  items: [
    {
      id: number;
      applicant_full_name: string;
      car_brand_model: string;
      karnameh_pricing_lower_bound: number;
      car_brand_model_pe: string;
      karnameh_pricing_upper_bound: number;
      address: string;
      car_brand_model_year: string;
      status?: string;
      expiration_time?: string | null;
      is_new_financial_model: boolean;
    }
  ];
  total: number;
  page: number;
  size: number;
  pages: number;
}

export interface SalesNoteList {
  agent_fullname: string;
  category: string;
  content: string;
  uuid: string;
  created_at: string;
  last_modified?: string;
}

export interface SendNewNote {
  order_id: string | undefined;
  category: string;
  content: string;
}

export interface UpdateNote {
  category: string;
  content: string;
}

export interface NoteById {
  agent_fullname: string;
  category: string;
  content: string;
  uuid: string;
}

export interface CarStatus {
  car_status?: string;
  is_leasing_available?: boolean;
}

const getSaleExpertRequest = async (status: string, page: number) => {
  return http2<SalesExpert>({
    method: "GET",
    url: `/sales-expert/requests?status=${status}&page=${page}&size=10`
  });
};

const getSaleExpertRequestKey = "getSaleExpertRequest";

export const useGetSaleExpert = (status: string, page = 1) => {
  return useQuery({
    queryKey: [getSaleExpertRequestKey, status, page],
    staleTime: 0,
    queryFn: () => {
      return getSaleExpertRequest(status, page)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return {} as SalesExpert;
        });
    }
  });
};

const getSalesNoteList = async (id: string) => {
  return http2<SalesNoteList[]>({
    method: "GET",
    url: `/note/v1/note/concierge-sale/${id}/`
  });
};

const getSalesNoteListKey = "getSalesNoteList";

export const useGetSaleNoteList = (id: string) => {
  return useQuery({
    queryKey: [getSalesNoteListKey],
    staleTime: 0,
    queryFn: () => {
      return getSalesNoteList(id)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return [] as SalesNoteList[];
        });
    }
  });
};

const postConciergeSaleNote = (data: SendNewNote) => {
  return http2({
    method: "POST",
    url: `/note/v1/note/concierge-sale/register/`,
    data
  });
};

const postConciergeSaleNoteKey = "postConciergeSaleNote";

export const usePostConciergeSaleNote = () => {
  return useMutation({
    mutationKey: [postConciergeSaleNoteKey],
    mutationFn: (data: SendNewNote) => {
      return postConciergeSaleNote(data);
    }
  });
};

const updateConciergeSaleNote = (uuid: string, data: UpdateNote) => {
  return http2({
    method: "PUT",
    url: `/note/v1/note/concierge-sale/${uuid}`,
    data
  });
};

const updateConciergeSaleNoteKey = "updateConciergeSaleNote";

export const useUpdateConciergeSaleNote = (uuid: string) => {
  return useMutation({
    mutationKey: [updateConciergeSaleNoteKey],
    mutationFn: (data: UpdateNote) => {
      return updateConciergeSaleNote(uuid, data);
    }
  });
};

const updateCarStatus = (id: string, data: CarStatus) => {
  return http2({
    method: "PUT",
    url: `/concierge-sale/${id}`,
    data
  });
};

const updateCarStatusKey = "updateCarStatus";

export const useUpdateCarStatus = (id: string) => {
  return useMutation({
    mutationKey: [updateCarStatusKey],
    mutationFn: (data: CarStatus) => {
      return updateCarStatus(id, data);
    }
  });
};

/*--- Car Status Colors   ---*/

interface CarStatusDetail {
  car_color: string;
  car_status: {
    extra_info: {
      count: number | string | null;
    } | null;
    selected_part: string | null;
  };
  roof_painted_or_replacement: boolean;
  front_chassis_painted_or_damaged: boolean;
  rear_chassis_painted_or_damaged: boolean;
  car_options: string | null;
  car_status_description: string | null;
}

export type colorsResponse = { [key: string | number]: string };

const getCarStatusColors = () => {
  return http2<colorsResponse>({
    method: "GET",
    url: `/colors/`
  }).then((res) => res.data);
};

const getCarStatusColorsKey = "getCarStatusColors";

export const useGetCarStatusColors = (
  options?: UseQueryOptions<Awaited<ReturnType<typeof getCarStatusColors>>>
) => {
  const queryFn = () => {
    return getCarStatusColors();
  };

  return useQuery({
    queryKey: [getCarStatusColorsKey],
    queryFn,
    staleTime: 0,
    ...options
  });
};

/*--- update Car status page  ---*/

export const updateCarStatusDetail = (id: string, data: CarStatusDetail) => {
  return http2({
    method: "PUT",
    url: `/concierge-sale/${id}`,
    data
  });
};

const updateCarStatusDetailKey = "updateCarStatusDetail";

export const useUpdateCarStatusDetail = (id: string) => {
  return useMutation({
    mutationKey: [updateCarStatusDetailKey],
    mutationFn: (data: CarStatusDetail) => {
      return updateCarStatusDetail(id, data);
    }
  });
};

/*--- Calculate Price API  ---*/

interface PriceForm {
  price: number | null;
  advertisement_price: number | null;
  karnameh_commission: number | null;
  karnameh_commission_percentage?: number | null;
}

export const getCalculatePrice = (
  price: number | null,
  karnameh_commission: number | null
) => {
  return http2<PriceForm>({
    method: "GET",
    url: `/negotiated-price/calculate/?price=${price}&karnameh_commission=${karnameh_commission}`
  });
};

const getCalculatePriceKey = "getCalculatePrice";

export const useGetCalculatePrice = (
  price: number | null,
  karnameh_commission: number | null,
  id: number
) => {
  return useQuery({
    queryKey: [getCalculatePriceKey, id],
    staleTime: 0,
    queryFn: () => {
      return getCalculatePrice(price, karnameh_commission).then((res) => {
        return res.data;
      });
    }
  });
};

/*--- Post Negotiated Price API ---*/

interface NegotiatedPrice {
  price: number | null;
  advertisement_price: number | null;
  karnameh_commission: number | null;
}

const postNegotiatedPrice = (id: number, data: NegotiatedPrice) => {
  return http2({
    method: "POST",
    url: `/negotiated-price/submit/${id}/`,
    data
  });
};

const postNegotiatedPriceKey = "postNegotiatedPrice";

export const usePostNegotiatedPrice = (id: number) => {
  return useMutation({
    mutationKey: [postNegotiatedPriceKey],
    mutationFn: (data: NegotiatedPrice) => {
      return postNegotiatedPrice(id, data);
    }
  });
};

/*--- Get Cancel Reason API ---*/

export const getCancelReason = () => {
  return http2<{ [key: string]: string }>({
    method: "GET",
    url: `/concierge-sale/cancel/cancel_reasons/`
  });
};

const getCancelReasonKey = "getCancelReason";

export const useGetCancelReason = () => {
  return useQuery({
    queryKey: [getCancelReasonKey],
    staleTime: 0,
    queryFn: () => {
      return getCancelReason().then((res) => {
        return res.data;
      });
    }
  });
};

/*--- Post Cancel Reason API ---*/

interface CancelReasons {
  content: string;
  cancel_reason: string;
}

const postCancelReason = (id: string, data: CancelReasons) => {
  return http2({
    method: "POST",
    url: `/concierge-sale/cancel/${id}/`,
    data
  });
};

const postCancelReasonKey = "postCancelReason";

export const usePostCancelReason = (id: string) => {
  return useMutation({
    mutationKey: [postCancelReasonKey],
    mutationFn: (data: CancelReasons) => {
      return postCancelReason(id, data);
    }
  });
};

/*--- update address API ---*/

interface UpdateRequest {
  address?: string;
  usage?: number;
  car_brand_model?: string;
  status?: string;
  car_brand_model_year?: string;
  car_color?: string;
}

const updateInfoData = (id: string, data: UpdateRequest) => {
  return http2({
    method: "PUT",
    url: `/concierge-sale/${id}`,
    data
  });
};

const updateAddressKey = "updateAddress";

export const useUpdateInfoData = (id: string) => {
  return useMutation({
    mutationKey: [updateAddressKey],
    mutationFn: (data: UpdateRequest) => {
      return updateInfoData(id, data);
    }
  });
};

/*--- Post Deal Concierge Sale ---*/

export interface SubmitDealResoponse {
  deal_date: number | string;
  visitor_name: string;
  visitor_phone_number: string;
  price: number;
  karnameh_commission: number;
  sale_concierge_commission: number;
  buy_concierge_commission: number;
  pos_receipt_number: string;
  deal_description: string | null;
  buy_id: number;
  warranty_number: string | null;
  sale_concierge_commission_percent: number | null;
  buy_concierge_commission_percent: number | null;
  is_warrantied: boolean | null;
  affidavit_images: string[] | [];
  affidavit_documents: Record<
    "affidavit" | "payment_receipt" | "other",
    {
      name: string | null;
      url: string | null;
    } | null
  >;
}

export interface UpdateSubmitDealParams
  extends Omit<SubmitDealResoponse, "affidavit_documents"> {
  affidavit_documents: Partial<
    Record<"affidavit" | "payment_receipt" | "other", string | null>
  >;
}

const postSubmitDeal = (id: string, data: UpdateSubmitDealParams) => {
  return http2({
    method: "POST",
    url: `/concierge-sale/submit-deal/${id}/`,
    data
  });
};

const postSubmitDealKey = "postSubmitDeal";

export const usePostSubmitDeal = (id: string) => {
  return useMutation({
    mutationKey: [postSubmitDealKey],
    mutationFn: (data: UpdateSubmitDealParams) => {
      return postSubmitDeal(id, data);
    },
    onSuccess: () => undefined
  });
};

/*--- Update Deal Concierge Sale ---*/

const updateSubmitDeal = (id: number, data: UpdateSubmitDealParams) => {
  return http2({
    method: "PUT",
    url: `/concierge-sale/submit-deal/${id}`,
    data
  });
};

const updateSubmitDealKey = "updateSubmitDeal";

export const useUpdateSubmitDeal = (id: number) => {
  return useMutation({
    mutationKey: [updateSubmitDealKey],
    mutationFn: (data: UpdateSubmitDealParams) => {
      return updateSubmitDeal(id, data);
    },
    onSuccess: () => undefined
  });
};

/*--- Upload Car Photo ---*/
export interface UploadPhoto {
  file: File;
  field_type?: string;
  concierge_sale_id?: string;
}

const uploadCarPhoto = (data: UploadPhoto) => {
  return http2({
    method: "POST",
    url: `/concierge-sale/car-post/upload-file`,
    data
  });
};

const uploadCarPhotoKey = "uploadCarPhoto";

export const useUploadCarPhoto = () => {
  return useMutation({
    mutationKey: [uploadCarPhotoKey],
    mutationFn: (data: UploadPhoto) => {
      return uploadCarPhoto(data);
    }
  });
};

/*--- Post karnameh ads ---*/

export interface KarnamehAds {
  title: string;
  brand_model: string;
  persian_brand_model?: string;
  usage: number | null;
  year: number;
  color: string;
  city: number | null;
  third_party_insurance_deadline: number;
  price: number;
  gearbox: string;
  body_status: string;
  description: string;
  images: string[] | [];
  edit_reason?: string | null;
}

const postKarnamehAds = (id: string, data: KarnamehAds) => {
  return http2({
    method: "POST",
    url: `/concierge-sale/car-post/${id}/submit`,
    data
  });
};

const postKarnamehAdsKey = "postKarnamehAds";

export const usePostKarnamehAds = (id: string) => {
  return useMutation({
    mutationKey: [postKarnamehAdsKey],
    mutationFn: (data: KarnamehAds) => {
      return postKarnamehAds(id, data);
    }
  });
};

/*--- Update karnameh ads ---*/

const updateKarnamehAds = (id: number, data: KarnamehAds) => {
  return http2({
    method: "PUT",
    url: `/concierge-sale/car-post/${id}`,
    data
  });
};

const updateKarnamehAdsKey = "updateKarnamehAds";

export const useUpdateKarnamehAds = (id: number) => {
  return useMutation({
    mutationKey: [updateKarnamehAdsKey],
    mutationFn: (data: KarnamehAds) => {
      return updateKarnamehAds(id, data);
    }
  });
};

/*--- Submit Divar ADS ---*/

interface DivarAds {
  url: string;
}

const submitDivarAds = (id: number) => {
  return http2<DivarAds>({
    method: "GET",
    url: `/concierge-sale/car-post/${id}/divar/submit`
  });
};

const submitDivarAdsKey = "submitDivarAds";

export const useSubmitDivarAds = (id: number) => {
  return useQuery({
    queryKey: [submitDivarAdsKey],
    staleTime: 0,
    enabled: false,
    refetchOnWindowFocus: false,
    queryFn: () => {
      return submitDivarAds(id).then((res) => {
        return res.data;
      });
    }
  });
};

/*--- Edit Divar ADS ---*/

const editDivarAds = (id: number) => {
  return http2<DivarAds>({
    method: "GET",
    url: `/concierge-sale/car-post/${id}/divar/edit`
  });
};

const editDivarAdsKey = "editDivarAds";

export const useEditDivarAds = (id: number) => {
  return useQuery({
    queryKey: [editDivarAdsKey],
    staleTime: 0,
    enabled: false,
    refetchOnWindowFocus: false,
    queryFn: () => {
      return editDivarAds(id).then((res) => {
        return res.data;
      });
    }
  });
};

/*--- Ladder Divar ADS ---*/

const ladderDivarAds = (id: number) => {
  return http2({
    method: "GET",
    url: `/concierge-sale/car-post/${id}/divar/ladder`
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw new Error(err);
    });
};

const ladderDivarAdsKey = "ladderDivarAds";

export const useLadderDivarAds = (
  id: number,
  options?: UseQueryOptions<Awaited<ReturnType<typeof ladderDivarAds>>>
) => {
  return useQuery({
    queryKey: [ladderDivarAdsKey],
    staleTime: 0,
    retry: false,
    enabled: false,
    refetchOnWindowFocus: false,

    queryFn: () => {
      return ladderDivarAds(id);
    },
    ...options
  });
};

/*--- Get Karnameh ads ---*/

const getKarnamehAds = (id: number) => {
  return http2<KarnamehAds>({
    method: "GET",
    url: `/concierge-sale/car-post/${id}/car-post-detail`
  });
};

const getKarnamehAdsKey = "getKarnamehAds";

export const useGetKarnamehAds = (id: number) => {
  return useQuery({
    queryKey: [getKarnamehAdsKey],
    staleTime: 0,
    queryFn: () => {
      return getKarnamehAds(id).then((res) => {
        return res.data;
      });
    }
  });
};

/*--- Ladder History ---*/
export interface LadderDates {
  concierge_sale_id: number;
  created_at: string;
  id: number;
  user_id: number;
}

const getLadderHistory = (id: number) => {
  return http2<LadderDates[]>({
    method: "GET",
    url: `/concierge-sale/car-post/${id}/divar/ladder-history`
  });
};

const getLadderHistoryKey = "getLadderHistory";

export const useGetLadderHistory = (id: number) => {
  return useQuery({
    queryKey: [getLadderHistoryKey, id],
    staleTime: 0,
    queryFn: () => {
      return getLadderHistory(id).then((res) => {
        return res.data;
      });
    }
  });
};

/*--- Search All Requests ---*/

const getSearchAllRequest = async (search: string) => {
  return http2<SalesExpert>({
    method: "GET",
    url: `/sales-expert/requests?search=${search}`
  });
};

const getSearchAllRequestKey = "getSearchAllRequest";

export const useGetSearchAllRequest = (search: string) => {
  return useQuery({
    queryKey: [getSearchAllRequestKey, search],
    staleTime: 0,
    queryFn: () => {
      return getSearchAllRequest(search).then((res) => {
        return res.data;
      });
    }
  });
};

/*--- Attach Divar Ads ---*/

const postAttachDivarAds = (
  concierge_sale_id: number,
  inspection_id: number,
  data: null
) => {
  return http2({
    method: "POST",
    url: `/concierge-sale/v2/car-post/${concierge_sale_id}/${inspection_id}/attach-report`,
    data
  });
};

const postAttachDivarAdsKey = "postAttachDivarAds";

export const usePostAttachDivarAds = (
  concierge_sale_id: number,
  inspection_id: number
) => {
  return useMutation({
    mutationKey: [postAttachDivarAdsKey],
    mutationFn: (data: null) => {
      return postAttachDivarAds(concierge_sale_id, inspection_id, data);
    }
  });
};

/*--- Calculate Commission ---*/

interface CommissionPostData {
  karnameh_commission: number;
  sale_concierge_commission: number;
  buy_concierge_commission: number;
}

interface CommissionGetData {
  buy_commission: number;
  sale_commission: number;
}

const calculateCommission = (data: CommissionPostData) => {
  return http2<CommissionGetData>({
    method: "POST",
    url: "/concierge-sale/submit-deal/calculate-commission",
    data
  });
};

export const useCalculateCommission = () => {
  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof calculateCommission>>,
    CommissionPostData
  > = (data) => {
    return calculateCommission(data);
  };

  const mutationKey: MutationKey = [
    "/concierge-sale/submit-deal/calculate-commission"
  ];

  return useMutation<
    Awaited<ReturnType<typeof calculateCommission>>,
    AxiosError<GlobalErrorModel>,
    CommissionPostData
  >({
    mutationKey,
    mutationFn,
    onSuccess: () => undefined
  });
};

//#region Infinite Scroll Pagination
interface PaginationData {
  items: [
    {
      id: number;
      applicant_full_name: string;
      car_brand_model: string;
      karnameh_pricing_lower_bound: number;
      karnameh_pricing_upper_bound: number;
      address: string;
      car_brand_model_pe: string;
      car_brand_model_year: string;
      phone_number: string;
      status: string;
      city: string;
      expiration_time: string | null;
      is_new_financial_model: boolean;
    }
  ];
  total: number;
  page: number;
  size: number;
  pages: number;
}

const getPaginationData = async (status: string, page: number) => {
  return http2<PaginationData>({
    method: "GET",
    url: `/sales-expert/requests`,
    params: {
      status,
      page,
      size: 10
    }
  });
};

const getPaginationDataKey = "getPaginationData";

export const useGetPaginationData = (status: string, pageParam: number) => {
  return useInfiniteQuery({
    queryKey: [getPaginationDataKey, status, pageParam],
    queryFn: ({ pageParam = 1 }) => {
      return getPaginationData(status, pageParam).then((res) => {
        return res.data;
      });
    },
    getNextPageParam: (lastPage) => {
      return lastPage?.page !== lastPage.pages && lastPage.pages
        ? lastPage.page + 1
        : undefined;
    }
  });
};

//#endregion

//#region Get Sale Expert Detal Version 2

export interface CarPostData extends KarnamehAds {
  state: string;
  edit_reason: string | null;
}

interface SaleExpertDetailV2 {
  id: number;
  applicant_full_name: string;
  phone_number: string;
  status: string;
  car_brand_model: string;
  car_brand_model_fa: string;
  car_brand_model_year: string;
  expiration_time: string | null;
  usage: number;
  car_status: {
    extra_info: {
      count: number | null;
    };
    selected_part: string | null;
  };
  dealership_manage_token: string | null;
  karnameh_pricing_lower_bound: number;
  karnameh_pricing_upper_bound: number;
  address: string;
  django_panel_link: string;
  car_color: string;
  roof_painted_or_replacement: boolean;
  front_chassis_painted_or_damaged: boolean;
  rear_chassis_painted_or_damaged: boolean;
  djalali_year: number;
  is_car_health_filled: boolean;
  car_options: string | null;
  car_status_description: string | null;
  is_new_financial_model: boolean;
  concierge_sale_price_agreement:
    | []
    | {
        submission_date: string;
        price: number;
        advertisement_price: number;
        karnameh_commission: number;
      }[];
  pricing_team_info: {
    karnameh_pricing_lower_bound: number | null;
    karnameh_pricing_upper_bound: number | null;
    price_expert_name: string | null;
  };

  deals?: {
    deal_date: string;
    visitor_name: string;
    visitor_phone_number: string;
    buy_id: number;
    price: number;
    karnameh_commission: number;
    sale_concierge_commission_percent: number | null;
    sale_concierge_commission: number;
    buy_concierge_commission_percent: number | null;
    buy_concierge_commission: number;
    pos_receipt_number: string;
    deal_description: string;
    warranty_number: string | null;
    is_warrantied: boolean | null;
    id: number;
    affidavit_images: string[] | [];
    affidavit_documents: {
      affidavit: {
        name: string;
        url: string;
      };
      payment_receipt: {
        name: string;
        url: string;
      };
      other: {
        name: string;
        url: string;
      };
    };
  } | null;
  car_post?: CarPostData | null;
  car_post_token?: string;
  karnameh_post_link?: string;
  cancel_reason: string;
  city: number | null;
  city_name: string | null;
  operator_note: string | null;
  health_state: string | null;
  lead_health_notif: string | null;
  is_leasing_available?: boolean;
  token: string | null;
  is_canceled: boolean;
  inspections:
    | [
        {
          id: number;
          name: string;
          phone_number: string;
          state: string;
          created_at: string;
          report_link: string;
        }
      ]
    | [];
}

const getSaleExpertDetailV2 = async (id: number) => {
  return http2<SaleExpertDetailV2>({
    method: "GET",
    url: `/sales-expert/v2/requests/${id}`
  });
};

const getSaleExpertDetailV2Key = "getSaleExpertDetailV2";

export const useGetSaleDetailV2 = (
  id: number,
  options?: UseQueryOptions<unknown, unknown, SaleExpertDetailV2>
) => {
  return useQuery({
    queryKey: [getSaleExpertDetailV2Key, id],
    staleTime: 0,
    // enabled: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryFn: () => {
      return getSaleExpertDetailV2(id)
        .then((res) => {
          const data = res.data;

          data.concierge_sale_price_agreement =
            data.concierge_sale_price_agreement.sort((a, b) => {
              if (
                new Date(a.submission_date).getTime() <
                new Date(b.submission_date).getTime()
              ) {
                return 1;
              }
              return -1;
            });
          return data;
        })
        .catch(() => {
          return {} as SaleExpertDetailV2;
        });
    },
    ...options
  });
};

//#endregion

//#region gets sales expert timeslots
export interface GetSalesExpertTimeItem {
  available_center: boolean;
  available_in_person: boolean;
  end: string; // "{number}{number}:{number}{number}"
  final_price: number;
  is_immediate: boolean;
  pk: number;
  price_diff: number;
  price_diff_reason: string;
  start: string; // "{number}{number}:{number}{number}"
  start_timestamp: number;
  title: string;
}

interface GetSalesExpertTimeResponse {
  data: GetSalesExpertTimeItem[];
}

interface GetSalesExpertTimeParams {
  city: number | null;
  brand_model: string;
}

const getSalesExpertTime = async (params: GetSalesExpertTimeParams) => {
  return httpRest<GetSalesExpertTimeResponse>({
    method: "GET",
    url: "/evaluation/v1/sales-expert-time/",
    params: {
      city_id: params.city === 1763 ? 1 : params.city,
      brand_model: params.brand_model
    }
  }).then((res) => {
    if (res.data.data && Array.isArray(res.data.data)) {
      return res.data.data;
    } else {
      return [];
    }
  });
};

const getSaleExpertTimeSlotWithOrderId = async (id: number) => {
  try {
    const saleExpertDetails = await getSaleExpertDetailV2(id);

    const saleExpertTimeSlots = await getSalesExpertTime({
      city: saleExpertDetails.data.city,
      brand_model: saleExpertDetails.data.car_brand_model
    });

    return saleExpertTimeSlots;
  } catch (err) {
    return Promise.reject();
  }
};

const getSaleExpertTimeSlotWithOrderIdKey = "getSaleExpertTimeSlotWithOrderId";

export const useGetSaleExpertTimeSlotWithOrderId = (
  id: number,
  options?: UseQueryOptions<
    unknown,
    unknown,
    Awaited<ReturnType<typeof getSaleExpertTimeSlotWithOrderId>>
  >
) => {
  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSaleExpertTimeSlotWithOrderId>>
  > = () => {
    return getSaleExpertTimeSlotWithOrderId(id);
  };

  const queryKey: QueryKey = [getSaleExpertTimeSlotWithOrderIdKey, id];

  return useQuery({
    queryFn,
    queryKey,
    ...options
  });
};

//#endregion

interface PostCreateInspectionBody {
  customer_name?: string;
  phone_number?: string;
  city?: number;
  center?: string;
  brand_model?: string;
  visit_place: string; //
  description?: string;
  time_slot_id: number; //
  concierge_sale?: string;
  visit_time: string; // 10:30
  selected_package?: string;
  payment_type?: string;
  platform?: string;
  city_id?: number;
  service_type?: string;
  inspection_center?: string;
  shift_id?: number;
}

// interface CreateInspectionResponse {

// }

const postCreateInspection = (
  data: PostCreateInspectionBody,
  id: string | undefined
) => {
  return http2({
    method: "POST",
    url: `/inspection/${id}`,
    data
  });
};

export const usePostCreateInspection = (
  id: string | undefined,
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof postCreateInspection>>,
    unknown,
    PostCreateInspectionBody
  >
) => {
  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postCreateInspection>>,
    PostCreateInspectionBody
  > = (params) => {
    return postCreateInspection(params, id);
  };

  const mutationKey = ["postCreateInspection"];

  return useMutation({
    mutationFn,
    mutationKey,
    ...options
  });
};

//#region pricing history concierge sale

interface GetPricingHistoryList {
  concierge_sale_id: number;
  price_upper_bound: number;
  price_lower_bound: number;
  price_expert_name: string;
  pricing_description: string;
  car_detail: {
    car_brand_model?: string;
    car_brand_model_year?: number;
    djalali_year?: number;
    usage?: number;
    car_color?: string;
    car_status?: { selected_part: string };
    car_options?: string;
    persian_car_status?: string;
    description?: string;
  };
  created_at: string;
}

const getPricingHistoryList = async (id: number) => {
  return http2<GetPricingHistoryList[]>({
    method: "GET",
    url: `/concierge-sale/backoffice/pricing/pricing-history/${id}`
  }).then((res) => res.data);
};

const getPricingHistoryListKey = "getPricingHistoryList";

export const useGetPricingHistoryList = (
  id: number,
  options?: UseQueryOptions<Awaited<ReturnType<typeof getPricingHistoryList>>>
) => {
  return useQuery({
    queryKey: [getPricingHistoryListKey, id],
    staleTime: 0,
    queryFn: () => {
      return getPricingHistoryList(id);
    },
    ...options
  });
};

//#endregion

//#region share concierge sale

export interface ConciergeSaleShare {
  phone_number?: string;
  lead_info_and_price?: boolean;
  car_post_links?: boolean;
  last_inspection?: boolean;
  leasing_landing?: boolean;
  last_inspection_report_link?: string | null;
}

const postConciergeSaleShare = async (id: number, data: ConciergeSaleShare) => {
  return http2<ConciergeSaleShare>({
    method: "POST",
    url: `/sales-expert/${id}/share`,
    data
  });
};

const postConciergeSaleShareKey = "postConciergeSaleShare";

export const usePostConciergeSaleShare = (id: number) => {
  return useMutation({
    mutationKey: [postConciergeSaleShareKey],
    mutationFn: (data: ConciergeSaleShare) => {
      return postConciergeSaleShare(id, data);
    }
  });
};

//#endregion

//#region

interface RegisterCarPostParams {
  brand_model: string;
  usage: number;
  year: number;
  color: string;
  city: number | null;
  conciergeSaleId: number;
}

interface RegisterCarPostResponse {
  token: string;
}

export const registerCarPost = (params: RegisterCarPostParams) => {
  return http2<RegisterCarPostResponse>({
    method: "POST",
    url: `/concierge-sale/v2/car-post/${params.conciergeSaleId}`,
    data: params
  }).then((res) => res.data);
};

export const useRegisterCarPost = (
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof registerCarPost>>,
    unknown,
    RegisterCarPostParams
  >
) => {
  return useMutation({
    mutationFn: (params: RegisterCarPostParams) => {
      return registerCarPost(params);
    },
    ...options
  });
};

//#endregion
